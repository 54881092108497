import React from 'react';
import {Link, StaticQuery, graphql} from 'gatsby';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Section from './Section';
import sizes from './sizes';
import {Instagram, Facebook, Vkontakte} from './Icons';
import './index.css';

const title = 'Таня Сахарова';
const descr =
  'Фотограф Таня Сахарова. Фотоистории о людях.';
const shareImg = '/share.jpg';
const keywords =
  'Фотограф Таня Сахарова, портретная съёмка, репортажная съёмка';

export const tt = {
  portrait: 'Портреты',
  report: 'Репортаж',
  nature: 'Природа',
  family: 'Семья',
};

const Header = styled.header``;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.footer`
  margin-top: auto;
  text-align: center;
`;

const Main = styled.main`
  flex: 1;
  flex-shrink: 0;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  h1 {
    margin: 0;
    max-width: 25%;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Alegreya Sans', sans-serif;
  }
`;

const Nav = styled.nav`
  margin-left: auto;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  > * {
    margin: 0 0 0 8px;

    &:first-child {
      margin-right: 4px;
    }
  }

  svg {
    display: block;
    position: relative;
    top: 0px;
  }
`;

const siz = s => `${s}x${s}`;

const Layout = ({children, location, data}) => {
  const url = location.pathname;
  return (
    <Wrapper>
      <Helmet title={title}>
        <meta property="og:title" content={title} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:image" meta="image" content={shareImg} />
        <meta itemprop="image" content={shareImg} />
        <meta property="og:image:width" content="1500" />
        <meta property="og:image:height" content="1000" />
        <meta name="keywords" content={keywords} />
        <meta property="og:description" meta="description" content={descr} />
        <meta name="description" content={descr} />
        <meta property="og:type" content="website" />

        <link rel="shortcut icon" href="/favicon.ico" />
        {sizes.favicons.map(s => (
          <link
            key={siz(s)}
            sizes={siz(s)}
            rel="icon"
            type="image/png"
            href={`/favicon/${s}.png`}
          />
        ))}
        {sizes.appleIcons.map(s => (
          <link
            key={siz(s)}
            sizes={siz(s)}
            rel="apple-touch-icon-precomposed"
            href={`/favicon/${s}.png`}
          />
        ))}
        <meta name="msapplication-TileImage" content="/favicon/144.png" />
        <meta name="msapplication-square70x70logo" content="/favicon/70.png" />
        <meta
          name="msapplication-square150x150logo"
          content="/favicon/150.png"
        />
        <meta
          name="msapplication-square310x310logo"
          content="/favicon/310.png"
        />
      </Helmet>
      <Header>
        <Section>
          <HeaderSection>
            <h1>{url === '/' ? title : <Link to="/">{title}</Link>}</h1>
            <Nav>
              <Link to="/about/">Обо мне</Link>
              <a href="https://instagram.com/saharovata">
                <Instagram />
              </a>
              <a href="https://facebook.com/saharovata">
                <Facebook />
              </a>
              <a href="https://vk.com/saharovata">
                <Vkontakte />
              </a>
            </Nav>
          </HeaderSection>
        </Section>
      </Header>
      <Main>{children}</Main>
      <Footer>
        <Section>
          © <Link to="/">{title}</Link> ·{' '}
          <a href="mailto:saharovata@gmail.com">saharovata@gmail.com</a>
        </Section>
      </Footer>
    </Wrapper>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            languages {
              defaultLangKey
              langs
            }
          }
        }
      }
    `}
    render={data => <Layout {...props} />}
  />
);
