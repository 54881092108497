import styled from 'styled-components';

const StyledSection = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding: 20px;
  width: 100%;

  p {
    line-height: 1.3em;
    font-size: 16px;
    font-weight: 300;
  }

  ul,
  ol {
    line-height: 1.3em;
  }

  ol {
    list-style: none;
    counter-reset: item;
    li {
      position: relative;
      counter-increment: item;
      &:before {
        margin-right: 10px;
        content: counters(item, '.') ' ';
        display: inline-block;
        position: absolute;
        right: 100%;
        opacity: 0.5;
      }
    }
  }

  @media (min-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;

    p {
      font-size: 20px;
    }
  }
`;

export default StyledSection;
